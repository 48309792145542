<template>
  <b-card>
    <b-tabs class="tab-primary">
      <!-- <b-tab title="Info de la empresa New">
        <company-info-new @changeTabNotifications="changeTabNotifications"/>
      </b-tab> -->
      <b-tab title="Info de la empresa">
        <company-info />
      </b-tab>
      <b-tab :lazy="isLazy.employee" title="Usuarios empleados" @click="showTab('employee')">
        <employees/>
      </b-tab>
      <b-tab :lazy="isLazy.accounts" title="Mis cuentas" @click="showTab('accounts')" v-if="show.accounts">
        <tab-multi-account-for-platform />
      </b-tab>
      <b-tab :lazy="isLazy.warehouse" @click="showTab('warehouse')" title="Bodegas">
        <warehouse-companies/>
      </b-tab>
      <b-tab :lazy="isLazy.carrirConfig" @click="showTab('carrirConfig')" title="Couriers / Configuración" v-if="show.carrirConfig">
        <tab-for-platform />
      </b-tab>
      <b-tab :lazy="isLazy.marketplace" title="Marketplace" @click="showTab('marketplace')" v-if="show.mkp">
        <tab-marketplace-company />
      </b-tab>
      <b-tab :lazy="isLazy.protocol" @click="showTab('protocol')" title="Protocolo">
        <list />
      </b-tab>
      <b-tab :lazy="isLazy.integrations" @click="showTab('integrations')" title="Integraciones" v-if="show.integrations">
        <tab-integrations-company />      
      </b-tab>
      <b-tab :lazy="isLazy.storeMang" @click="showTab('storeMang')" title="Gestión tienda" v-if="show.storeMang">
        <store-management />      
      </b-tab>
      <b-tab :lazy="isLazy.nofication" @click="showTab('nofication')" title="Notificaciones" ref="notificationTab">
        <Main-nofication :changeTabWebhook="changeTabWebhook"/>
      </b-tab>
      <b-tab :lazy="isLazy.extraFields" @click="showTab('extraFields')" title="Campos adicionales">
        <Main/>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import CompanyInfo from './CompanyInfo/CompanyInfo'
/* import CompanyInfoNew from './CompanyInfo/CompanyInfoNew' */
import Employees from './Employees/Employees.vue'
import TabMultiAccountForPlatform from '@/views/modules/configuration/shippers/edit-shippers/tabs/TabMultiAccountForPlatform.vue'
import WarehouseCompanies from './warehouses/WarehouseCompanies.vue'
import TabForPlatform from './couriers-configuration/TabForPlatform.vue'
import TabMarketplaceCompany from './Marketplaces/TabMarketplaceCompany.vue'
import List from '@/views/modules/protocol-company/List.vue'
import TabIntegrationsCompany from './integrationsTab/TabIntegrationsCompany.vue'
import MainNofication from './notifications/Main.vue'
import Main from './companies-extra-fields/Main.vue'
import storeManagement from '../../store-management/StoreManagement.vue'


export default {
  name: 'tabs',
  components: {
    CompanyInfo,
    /* CompanyInfoNew, */
    Employees,
    TabMultiAccountForPlatform,
    WarehouseCompanies,
    TabForPlatform,
    TabMarketplaceCompany,
    List,
    TabIntegrationsCompany,
    MainNofication,
    Main,
    storeManagement
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      companyInfo: 'getCompanyInformation'
    })
  },
  data () {
    return {
      tabIndex: 0,
      isLazy: {
        employee: true, //Empleados
        accounts: true, //Mis cuentas
        warehouse: true, //Bodegas
        carrirConfig: true, //Corrier Config
        marketplace: true, //MKP
        protocol: true, //Protocolo
        integrations: true, //Integraciones
        storeMang: true, //Gestión tienda
        nofication: true, //Notificaciones
        extraFields: true //Campos adicionales
      },
      loading: {
        first: true
      },
      show: {
        accounts: false,
        carrirConfig: false,
        mkp: false,
        integrations: false,
        storeMang: false
      },
      changeTabWebhook: false
    }
  },
  watch: {
    mySession () {
      this.setConditionaTabs()
    },
    companyInfo() {
      this.show = {
        ...this.show,
        integrations: this.companyInfo?.data?.show_integrations_seller,
        storeMang: this.companyInfo?.data?.can_view_entel_slots
      }
    }
  },
  mounted () {
    this.setConditionaTabs()
  },
  methods: {
    showTab(name) {
      if (this.isLazy[name]) this.isLazy[name] = false
    },
    setConditionaTabs() {
      if (!this.mySession?.id) return
      if (['admin'].includes(this.$session.get('cas_user')?.role)) {
        this.show = {
          ...this.show,
          accounts: true,
          carrirConfig: true,
          mkp: true
        }
      }
      if (['marketplace'].includes(this.$session.get('cas_user')?.role)) {
        this.show = {
          ...this.show,
          carrirConfig: true
        }
      }
    },
    changeTabNotifications () {
      this.changeTabWebhook = true
      this.$refs.notificationTab.activate()
    }
  }
}
</script>